import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const NAV_ITEMS = {
  about: { path: "/about", label: "The Joy of Tai Chi" },
  info: { path: "/tai-chi-info", label: "What is Tai Chi?" },
  history: { path: "/tai-chi-history", label: "The History of Tai Chi" },
  Certification: {
    path: "/tai-chi-certification",
    label: "Certification & the West",
  },
}

const AboutNav = ({ current }) => (
  <nav>
    {Object.keys(NAV_ITEMS).map(key => {
      const { path, label } = NAV_ITEMS[key]

      if (current === key) {
        return <span key={`span_${key}`}>{label}</span>
      }
      return (
        <Link key={`link_${key}`} to={path}>
          {label}
        </Link>
      )
    })}
  </nav>
)

AboutNav.propTypes = {
  current: PropTypes.string,
}

AboutNav.defaultProps = {
  current: `about`,
}

export default AboutNav
